const chartColors = ["#025197", "#0275d8"];

export const chartOptions = {
  allowHtml: true,
  colors: chartColors,
  backgroundColor: "#f8f9fa",
  alternatingRowStyle: false,
  timeline: {
    rowLabelStyle: {
      fontSize: 15,
      fontName: "Roboto",
    },
    barLabelStyle: {
      fontSize: 15,
      fontName: "Roboto",
    },
    groupByRowLabel: true,
    colorByRowLabel: true,
  },
};

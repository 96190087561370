import React from "react";
import "./ProjectCard.css";
import { LogoTextDisplay } from "../logo-text-display/LogoTextDisplay";
import { Project } from "../../interfaces/Project";
import { formatDate } from "../../utils/helpers";

interface Props {
  className?: string;
  project: Project;
  onClick?(): () => void;
}

export const ProjectCard: React.FC<Props> = ({ className, project, onClick }) => {
  return (
    <button onClick={onClick} className={`card project-card py-2 ${project.state} ${className}`}>
      <img
        className="card-img-top pb-0 pt-1"
        src={`${process.env.PUBLIC_URL}/images/logos/${project.customerLogo}`}
        alt=""
      />
      <div className="px-3 pt-2">
        <h2 className="card-title mb-1">{project.projectName}</h2>
        <p className="card-subtitle text-muted mb-2">{project.customerName}</p>
      </div>
      <div className="card-body py-0 pr-2">
        {project.endDate && ( // only render if date is set
          <LogoTextDisplay icon="bi bi-calendar4-event">
            <div className="col fw-bold">{formatDate(project.endDate)}</div>
          </LogoTextDisplay>
        )}

        {project.contactPersons.length > 0 && ( // only render if there are contact persons
          <LogoTextDisplay icon="bi bi-chat-left-dots">
            {project.contactPersons.map((person, i) => {
              return (
                <span key={i}>
                  {person}
                  {i === project.contactPersons.length - 1 ? "" : ","}
                </span>
              );
            })}
          </LogoTextDisplay>
        )}
      </div>
    </button>
  );
};

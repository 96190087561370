/**
 * adds one year to the provided date
 */
export const addOneYear = (date: Date) => {
  const year = date.getFullYear();
  return new Date(year + 1, date.getMonth(), date.getDate());
};

/**
 * calculates the time delta between two dates in weeks
 * @param firstDate
 * @param secondDate
 * @returns the time delta in weeks
 */
export const calcTimeDeltaInWeeks = (firstDate: Date, secondDate: Date): string => {
  const timeDeltaInMs = secondDate.getTime() - firstDate.getTime();
  const timeDeltaInWeeks = timeDeltaInMs / 1000 / 60 / 60 / 24 / 7;
  const roundedTimeDeltaInWeeks = Math.round(timeDeltaInWeeks * 10) / 10;
  return roundedTimeDeltaInWeeks.toString();
};

/**
 * calculates the time delta between two dates in days
 * @param firstDate
 * @param secondDate
 * @returns the time delta in days
 */
export const calcTimeDeltaInDays = (firstDate: Date, secondDate: Date): string => {
  const timeDeltaInMs = secondDate.getTime() - firstDate.getTime();
  const timeDeltaInDays = timeDeltaInMs / 1000 / 60 / 60 / 24;
  const roundedTimeDeltaInWeeks = Math.floor(timeDeltaInDays);
  return roundedTimeDeltaInWeeks.toString();
};

/**
 * formatts a date with the given locale
 * @param dateString - a date string in ISO notation
 * @param locale - the locale
 * @returns the formatted date/"?" if no date is set
 */
export const formatDate = (dateString: string, locale: string = "de-DE"): string => {
  const date: Date = new Date(dateString);
  return isNaN(date.valueOf()) ? "?" : date.toLocaleDateString(locale);
};

import React from "react";

interface Props {
  icon?: string;
  children?: React.ReactChild[] | React.ReactChild;
}

export const LogoTextDisplay: React.FC<Props> = ({ icon, children }) => {
  return (
    <div className="LogoTextDisplay row mb-1">
      {icon ? <div className={"col-2 " + icon}></div> : ""}
      <div className="col-10">{children}</div>
    </div>
  );
};

import React from "react";
import { projectStateType } from "../../interfaces/Project";
import "./StatusDisplay.css";

interface Props {
  status: projectStateType;
}

interface StatusData {
  classes: string;
  text: string;
}

const statusMap = new Map<projectStateType, StatusData>([
  ["done", { classes: "grey", text: "abgeschlossen" }],
  ["inProgress", { classes: "text-warning", text: "in Bearbeitung" }],
  ["ordered", { classes: "text-warning", text: "bestellt" }],
  ["waitingForOrder", { classes: "text-warning", text: "Warten auf Bestellung" }],
  ["inAcceptance", { classes: "text-success", text: "in Abnahme" }],
  ["accepted", { classes: "green", text: "akzeptiert" }],
  ["discontinued", { classes: "grey", text: "nicht fortgesetzt" }],
]);

export const StatusDisplay: React.FC<Props> = ({ status }) => {
  const mappedStatus = statusMap.get(status);
  return (
    <div className="status-display">
      <i className={`bi bi-circle-fill ${mappedStatus?.classes}`}></i>
      <span className="mx-1">{mappedStatus?.text}</span>
    </div>
  );
};

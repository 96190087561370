import React from "react";
import "./PersonsDisplay.css";

interface Props {
  persons: string[];
}

export const PersonsDisplay: React.FC<Props> = ({ persons }) => {
  if (persons.length === 0) {
    return <></>;
  }

  return (
    <div className="persons-display d-flex flex-wrap">
      {persons.map((person) => (
        <div key={person} className="card person-display d-print-none m-2">
          <div className="h-100 d-flex m-2 mt-2">
            <i className="bi bi-person-circle fs-4 mx-2"></i>
            <p className="name-display">{person}</p>
          </div>
        </div>
      ))}

      {/* fallback for print */}
      <div className="d-none d-print-block print-fallback">{persons.toString()}</div>
    </div>
  );
};

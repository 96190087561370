import ReactDOMServer from "react-dom/server";
import "./Timeline.css";
import { Project } from "../../interfaces/Project";
import { StatusDisplay } from "../status-display/StatusDisplay";
import { calcTimeDeltaInDays, formatDate } from "../../utils/helpers";
import { checkIfTimeLeft, checkIfOverdue } from "../../utils/projectHelpers";

const displayOverdue = (project: Project) => {
  if (checkIfOverdue(project)) {
    const timeDeltaInDays = calcTimeDeltaInDays(new Date(project.endDate), new Date());
    return <strong className="text-danger">{timeDeltaInDays} Tage überfällig</strong>;
  }
  return <></>;
};

const displayTimeLeft = (project: Project) => {
  if (checkIfTimeLeft(project)) {
    const timeDeltaInDays = calcTimeDeltaInDays(new Date(), new Date(project.endDate));
    return <strong className="text-success">noch {timeDeltaInDays} Tage</strong>;
  }
  return <></>;
};

/**
 * creates a tooltip for the provided project
 * @param project
 * @returns valid html
 */
export const createTooltip = (project: Project) => {
  const tooltip = (
    <div className="p-2" style={{ width: "30ch" }}>
      <div>
        <div className="row mb-2">
          <strong className="col-5">{project.projectName}</strong>
          <div className="col-7">
            <StatusDisplay status={project.state} />
          </div>
        </div>
        {displayOverdue(project)}
        {displayTimeLeft(project)}
        <div>{project.workLoad} Stunden</div>
        <div className="mt-1">
          <span>{formatDate(project.startDate)}</span>
          <span>-</span>
          <span>{formatDate(project.endDate)}</span>
        </div>
        <div className="mb-3">
          <span>{project.responsiblePersons.toString()}</span>
        </div>
      </div>
    </div>
  );

  return ReactDOMServer.renderToString(tooltip);
};

import React from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";

interface Props {}

const openInIFrame = window.self !== window.top;

export const Navbar: React.FC<Props> = () => {
  return (
    <div className="navbar d-print-none">
      <nav>
        <div className="nav nav-tabs" style={{ width: "100vw" }}>
          <NavLink exact to="/" activeClassName="active" className="nav-link">
            Timeline
          </NavLink>
          <NavLink exact to="./overview" activeClassName="active" className="nav-link">
            Project Overview
          </NavLink>
          {openInIFrame && (
            <a
              target="_blank"
              href="https://projects.mea.neckar.it/"
              className="btn btn-primary fullscreenButton bi bi-arrows-fullscreen mb-2 pb-2"
              rel="noopener noreferrer"
            >
              {" "}
            </a>
          )}
        </div>
      </nav>
    </div>
  );
};

import React from "react";
import "./LoadingBar.css";

export const LoadingBar: React.FC = () => {
  return (
    <div>
      <div className="lds-dual-ring"></div>
    </div>
  );
};

import React, { useEffect, useRef } from "react";
import "./FilterBar.css";
import { Project } from "../../interfaces/Project";
import { useLocation } from "react-router-dom";

interface Props {
  allProjects: Project[];
  setFilteredProjects: React.Dispatch<React.SetStateAction<Project[]>>;
}

export const FilterBar: React.FC<Props> = ({ setFilteredProjects: updateFilteredProjects, allProjects }) => {
  const customerNameFilter = useRef<HTMLSelectElement>(null);
  const resPersonFilter = useRef<HTMLSelectElement>(null);
  const oldProjectsFilter = useRef<HTMLInputElement>(null);

  const location = useLocation();

  useEffect(() => {
    applyFilters();
  }, [allProjects]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUniqueCustomerNames = () => {
    const uniqueCustomerNames: string[] = [];
    allProjects.forEach((project) => {
      const currentCustomerName = project.customerName;

      if (!uniqueCustomerNames.includes(currentCustomerName)) uniqueCustomerNames.push(currentCustomerName);
    });
    return uniqueCustomerNames;
  };

  const getUniqueResponsiblePersons = () => {
    const uniqueResponsiblePersons: string[] = [];

    allProjects.forEach((project) => {
      const currentResponsiblePersons = project.responsiblePersons;

      currentResponsiblePersons.forEach((resPerson) => {
        if (!uniqueResponsiblePersons.includes(resPerson)) {
          uniqueResponsiblePersons.push(resPerson);
        }
      });
    });
    return uniqueResponsiblePersons;
  };

  const applyFilters = () => {
    let filteredProjects: Project[] = allProjects;

    if (filteredProjects.length === 0) {
      return;
    }

    const customerNameFilterValue: string | undefined = customerNameFilter.current?.value;
    const resPersonFilterValue: string | undefined = resPersonFilter.current?.value;
    const oldProjectsFilterValue: boolean | undefined = oldProjectsFilter.current?.checked;

    if (customerNameFilterValue) {
      filteredProjects = filteredProjects.filter((project) => project.customerName === customerNameFilterValue);
    }

    if (resPersonFilterValue) {
      filteredProjects = filteredProjects.filter((project) =>
        project.responsiblePersons.includes(resPersonFilterValue),
      );
    }

    if (!oldProjectsFilterValue) {
      filteredProjects = filteredProjects.filter((project) => project.state !== "done");
    }

    updateFilteredProjects(filteredProjects);
  };

  const hideIfDetailView = () => {
    return location.pathname.startsWith("/detail") ? "d-none" : "";
  };

  return (
    <div className={`container-fluid filterBar ${hideIfDetailView()}`}>
      <div className="mx-4 my-3">
        <div className="row mb-5">
          <div className="col-12 col-sm-6">
            <select
              ref={customerNameFilter}
              className="form-select form-select-sm"
              name="filter"
              onChange={applyFilters}
            >
              <option key={""} value="">
                Alle Kunden
              </option>
              {getUniqueCustomerNames().map((customerName) => {
                return <option key={customerName}>{customerName}</option>;
              })}
            </select>
          </div>
          <div className="col-12 col-sm-6 pt-sm-0 pt-2">
            <select
              ref={resPersonFilter}
              className="form-select form-select-sm"
              name="responsiblePersonFilter"
              id="responsiblePersonFilter"
              onChange={applyFilters}
            >
              <option key={0} value="">
                alle Zuständigen
              </option>
              {getUniqueResponsiblePersons().map((resPerson) => {
                return <option key={resPerson}>{resPerson}</option>;
              })}
            </select>
          </div>
          <div className="col-12 mt-3">
            <input
              ref={oldProjectsFilter}
              type="checkbox"
              id="showOldProjects"
              className="form-check-input"
              onChange={applyFilters}
            />
            <label htmlFor="showOldProjects" className="px-2">
              <small>abgeschlossene Projekte</small>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { ProjectCard } from "../project-card/ProjectCard";
import { Project } from "../../interfaces/Project";
import { RouteComponentProps } from "react-router-dom";
import "./ProjectCardDeck.css";

interface Props extends RouteComponentProps {
  projects: Project[];
}

export const ProjectCardDeck: React.FC<Props> = ({ projects, history }) => {
  const openDetailView = (id: number) => {
    history.push("/detail/" + id);
  };

  return (
    <div className="container-fluid project-card-deck">
      <div className="row mx-1 mb-5">
        {projects.map((currentProject) => {
          return (
            <ProjectCard
              onClick={() => openDetailView(currentProject.id)}
              key={currentProject.id}
              className="col-12 col-md-4 col-lg-2"
              project={currentProject}
            />
          );
        })}
      </div>
    </div>
  );
};

import { Project, projectStateType } from "../interfaces/Project";

export const checkIfOverdue = (project: Project) => {
  const overdue: boolean = new Date(project.endDate).getTime() < Date.now();
  const okProjectStates: Array<projectStateType> = ["done", "accepted", "discontinued", "inAcceptance"];

  if (overdue && !okProjectStates.includes(project.state)) {
    return true;
  }
  return false;
};

export const checkIfTimeLeft = (project: Project) => {
  const overdue: boolean = new Date(project.endDate).getTime() > Date.now();
  const okProjectStates: Array<projectStateType> = ["done", "accepted", "discontinued"];

  if (overdue && !okProjectStates.includes(project.state)) {
    return true;
  }
  return false;
};

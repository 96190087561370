import { useState, useEffect } from "react";
import { ProjectCardDeck } from "./components/project-card-deck/ProjectCardDeck";
import { ProjectDetailView, DetailViewProps } from "./components/project-detail-view/ProjectDetailView";
import { Timeline } from "./components/timeline/Timeline";
import { ColorMap } from "./utils/ColorMap";
import { Navbar } from "./components/navbar/Navbar";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Project } from "./interfaces/Project";
import { ParsedProjectJson } from "./interfaces/ParsedProjectJson";
import { LoadingBar } from "./components/loading-bar/LoadingBar";
import projectJson from "./ProjectArray.json";
import { FilterBar } from "./components/filterBar/FilterBar";
import "./App.css";

type loadingState = "loading" | "done";

const groupProjectsByCustomer = (a: Project, b: Project) => {
  if (a.customerName !== b.customerName) {
    return 1;
  } else {
    return -1;
  }
};

export const App = () => {
  const [appLoadingState, setAppLoadingState] = useState<loadingState>("loading");

  const [initialProjects, setInitialProjects] = useState<Project[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);

  /**
   * Adds additional Properties to the parsed json string
   * @param jsonProjectArray the parsed json string
   * @returns an array projects with added ids and colors
   */
  const setUpProjects = (jsonProjectArray: Array<ParsedProjectJson>): Array<Project> => {
    const result: Array<Project> = jsonProjectArray.map((enrty: ParsedProjectJson, index: number) => {
      const customerName = enrty.customerName;
      const color = ColorMap.get(customerName) ?? "#1e2933";

      return { ...enrty, id: index, projectColor: color };
    });
    return result;
  };

  useEffect(() => {
    // load projects from json
    setTimeout(() => {
      const fetchedProjects: Array<Project> = setUpProjects(projectJson as Array<ParsedProjectJson>);
      const groupedProjects: Array<Project> = fetchedProjects.sort(groupProjectsByCustomer);
      setInitialProjects(groupedProjects);
      setAppLoadingState("done");
    }, 150);
  }, []);

  return (
    <BrowserRouter>
      <Navbar />
      <div className="bg-light">
        <FilterBar allProjects={initialProjects} setFilteredProjects={setFilteredProjects} />

        {appLoadingState === "loading" && <LoadingBar />}

        {appLoadingState === "done" && filteredProjects.length === 0 && (
          <div className="mx-5">keine Projekte die diesem Filter entsprechen</div>
        )}

        {appLoadingState === "done" && filteredProjects.length > 0 && (
          <Switch>
            <Route path="/" exact render={(props) => <Timeline {...props} projects={filteredProjects} />} />
            <Route
              path="/overview"
              exact
              render={(props) => <ProjectCardDeck {...props} projects={filteredProjects} />}
            />
            <Route
              path="/detail/:id"
              component={(props: DetailViewProps) => <ProjectDetailView {...props} projects={filteredProjects} />}
            />
          </Switch>
        )}
      </div>
    </BrowserRouter>
  );
};

export default App;

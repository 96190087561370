import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { LoadingBar } from "../loading-bar/LoadingBar";
import { StatusDisplay } from "../status-display/StatusDisplay";
import { PersonsDisplay } from "../person-display/PersonsDisplay";
import "./ProjectDetailView.css";
import { Project } from "../../interfaces/Project";
import { formatDate } from "../../utils/helpers";

export interface DetailViewProps extends RouteComponentProps<{ id: string }> {
  projects: Project[];
}

export const ProjectDetailView: React.FC<DetailViewProps> = ({ match, history, projects }) => {
  const [project, setProject] = useState<Project | null>(null);

  useEffect(() => {
    setProject(projects.filter((project) => project.id === parseInt(match.params.id))[0]);
  }, [projects]); // eslint-disable-line react-hooks/exhaustive-deps

  if (project != null) {
    return (
      <div className="project-detail-view container-fluid">
        <div className="card m-md-4 m-1">
          <img
            className="background-img p-3 pb-5"
            src={`${process.env.PUBLIC_URL}/images/logos/${project.customerLogo}`}
            alt=""
          />

          <div className="px-md-5 controls mt-4 px-4">
            <div className="d-flex justify-content-between">
              <div>
                <button
                  className="bi bi-arrow-left-short d-print-none fs-1 go-back"
                  onClick={() => {
                    history.goBack();
                  }}
                ></button>
              </div>
              <div>
                <button className="btn btn-dark bi bi-pencil-square d-print-none mx-2"></button>
                <button
                  className="btn bi bi-printer d-print-none btn-outline-dark"
                  onClick={() => window.print()}
                ></button>
              </div>
            </div>

            <div className="mb-3 mt-4">
              <div className="d-flex">
                <h2 className="mb-1" style={{ color: project.projectColor }}>
                  {project.projectName}
                </h2>
                <div className="text-muted d-print-none mt-2">
                  {project.contractNumber !== 1 ? project.contractNumber : ""}
                </div>
              </div>
              <h3 className="card-subtitle text-muted">{project.customerName}</h3>
            </div>

            <div className="card-content mt-5">
              <div className="d-print-none mb-5">
                <div className="fw-bold fs-5">Aufwand von {project.workLoad} Stunden</div>
                <div>
                  {formatDate(project.startDate)} - {formatDate(project.endDate)}
                </div>
                <StatusDisplay status={project.state} />
              </div>

              <div className="row mb-5">
                <div className="col-12 col-lg-3">
                  {project.responsiblePersons.length !== 0 && (
                    <div>
                      <h5 className="fw-bold fs-6">Zuständig </h5>
                      <PersonsDisplay persons={project.responsiblePersons} />
                    </div>
                  )}
                </div>
                <div className="col-12 col-lg-3">
                  {project.contactPersons.length !== 0 && (
                    <div className="mb-4">
                      <h5 className="fw-bold fs-6">Ansprechspersonen </h5>
                      <PersonsDisplay persons={project.contactPersons} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <LoadingBar />;
  }
};

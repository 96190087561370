import React from "react";
import { addOneYear } from "../../utils/helpers";
import { checkIfOverdue } from "../../utils/projectHelpers";
import "./Timeline.css";
import { LoadingBar } from "../loading-bar/LoadingBar";
import { Chart } from "react-google-charts";
import { Project } from "../../interfaces/Project";
import { chartOptions } from "./TimelineConfig";
import { createTooltip } from "./Tooltip";
import { RouteComponentProps } from "react-router-dom";
import { ReactGoogleChartEvent } from "react-google-charts/dist/types";

interface Props extends RouteComponentProps {
  projects: Project[];
}

type TimelineEntry = [string, string, string, string, number, Date, Date];

// format of the columns
const columsConfig = [
  { type: "string", id: "Customer" },
  { type: "string", id: "Project-name" },
  { type: "string", role: "style" }, // custom style for each bar
  { type: "string", role: "tooltip" },
  { type: "number", role: "interval" }, // workaround to pass the project id, this column will not be displayed
  { type: "date", id: "Start" },
  { type: "date", id: "End" },
];

/**
 * @param project
 * @returns custom css if a project is overdue
 */
const highlightIfOverdue = (project: Project) => {
  if (checkIfOverdue(project)) {
    return "color: #DC3544";
  }
  return "";
};

const generateTimelineData = (projects: Project[]) => {
  const data: Array<object | TimelineEntry> = [columsConfig];

  projects.forEach((project) => {
    const startDate = project.startDate ? new Date(project.startDate) : new Date(); // if no date is provided use current date
    const endDate = project.endDate ? new Date(project.endDate) : addOneYear(new Date()); // if no date is provided use current date + one year
    const customStyle = highlightIfOverdue(project);

    const formatedData: TimelineEntry = [
      project.customerName,
      project.projectName + ` (${project.workLoad} h)`,
      customStyle,
      createTooltip(project),
      project.id,
      startDate,
      endDate,
    ];

    data.push(formatedData);
  });
  return data;
};

// this components implements a timeline chart by react google charts (https://www.react-google-charts.com/examples/timeline)
export const Timeline: React.FC<Props> = ({ projects, history }) => {
  // custom events
  const chartEvents: ReactGoogleChartEvent[] = [
    {
      eventName: "select",
      callback: ({ chartWrapper }) => {
        const dataTable = chartWrapper.getDataTable();
        const selectedRowIndex = chartWrapper.getChart().getSelection()[0].row;
        const projectId: string | undefined = dataTable?.getFormattedValue(selectedRowIndex, 4); // the project-id is saved in column 4
        openDetailView(projectId ?? "0");
      },
    },
  ];

  const openDetailView = (id: string) => {
    history.push("/detail/" + id);
  };

  if (projects != null) {
    return (
      <div className="container-fluid timeline">
        <div>
          <div className="chart-wrapper mx-4 mb-4">
            <div>
              <Chart
                graph_id="timeline-chart"
                chartType="Timeline"
                height="750px"
                width="100%"
                data={generateTimelineData(projects)}
                options={chartOptions}
                chartEvents={chartEvents}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <LoadingBar />;
  }
};
